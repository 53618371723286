<script>
import { defineComponent, provide } from "vue";
import apiService from "@/core/services/ApiService";
import {inject, onMounted} from "vue";


export default defineComponent({
  name: "globalFunctions",
  components: {},
  props: {},
  emit: ["region"],

  setup(props, { emit }) {
    onMounted(() => {
      getRegions();
    });
    function getRegions(){
      apiService
        .get(`/get-regions`, `?column=name&orderType=asc`)
        .then(function (resp) {
          if (resp.data) {
            emit("region", resp.data.data);
          }
        });
    }
  },
});
</script>

<style scoped>

</style>